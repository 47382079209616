import { MEDIA_QUERY_MEDIUM } from "../styles/variables";

export default function screenResolution() {

  /*
   * @screenXsMin:480px;
   * @screenSmMin:640px;
   * @screenMdMin:1024px;
   * @screenLgMin:1400px;
   */

  return { isMediumUp: window.matchMedia(`(min-width: ${MEDIA_QUERY_MEDIUM})`).matches };

}
