import React from "react";
import styled from "styled-components";

const HamburgerStyles = styled.button`

  width: 30px;
  height: 30px;
  padding: 0;
  border: 0;
  background-color: var(--white);

  span {
    display: block;
    height: 2px;
    background-color: var(--black);
    border-radius: 15px;
    border: 1px solid transparent;


    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
`;

const HamburgerOpenStyles = styled(HamburgerStyles)`
  span {
    background-color: var(--red);
  }
`;


const Hamburger = ({ isOpen, setIsOpen }) => {

  const HamburgerType = isOpen ? HamburgerOpenStyles : HamburgerStyles;

  return (
    <HamburgerType onClick={() => setIsOpen(!isOpen)}>
      <span></span>
      <span></span>
      <span></span>
    </HamburgerType>
  );

};

export default Hamburger;
