import { MEDIA_QUERY_MEDIUM } from "../styles/variables";
import React from "react";
import styled from "styled-components";

const LogoWrapper = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  padding: 2rem;
  img {
    max-height: 50px;
  }


  @media (min-width: ${MEDIA_QUERY_MEDIUM}) {
    padding: 4rem 2rem;

    img {
      max-height: 75px;
    }

  }
`;

const Logos = ({ logos }) => (
  <LogoWrapper>
    { logos.map((logo, index) => (
      <img key={`logo_${index}`} src={`${logo.image.asset.url}?h=75`} alt={`${logo.name} logo`} />
    ))}
  </LogoWrapper>
);

export default Logos;
