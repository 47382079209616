import "normalize.css";
import { ApplicationStateProvider } from "../ApplicationState";
import Contact from "./Contact";
import Footer from "./Footer";
import GlobalStyles from "../styles/GlobalStyles";
import { graphql, useStaticQuery } from "gatsby";
import Header from "./Header";
import Logos from "./Logos";
import { MEDIA_QUERY_MEDIUM } from "../styles/variables";
import React from "react";
import styled from "styled-components";
import Typography from "../styles/Typography";

const SiteGridStyles = styled.div`
  max-width: 1000px;
  background: var(--white);
  
  @media (min-width: ${MEDIA_QUERY_MEDIUM}) {
    
    box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.044);
    border-radius: var(--borderRadius);
    margin: 2rem auto;

  }
  
  @media (min-width: ${MEDIA_QUERY_MEDIUM}) and (max-width: 1100px) {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  
`;

const Layout = ({ children, data }) => {
  
  // we do not want to display the contact component if we have a contact form component already on the page
  const displayContactComponent = data?.page?.components?.contactForm;
  
  let logos;
  
  if (data?.page?.displayLogos) {

    const { allLogos } = useStaticQuery(graphql`
      query {
        allLogos: allSanityLogo {
          nodes {
            name
            image {
              asset {
                url
              }
            }
            order
          }
        }
      }
    `);

    logos = allLogos.nodes.sort((a, b) => a.order - b.order);

  }

  return (
    <>
      <GlobalStyles />
      <Typography />
      {/* add a message box */}
      <SiteGridStyles>
        <Header />
        { children }
        { !displayContactComponent && <Contact /> }
        { logos && (
          <Logos logos={logos} />
        )}
        <Footer />
      </SiteGridStyles>

    </>
  );

};

export default Layout;
