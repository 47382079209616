import { MEDIA_QUERY_MEDIUM, MEDIA_QUERY_SMALL_MAX } from "../styles/variables";
import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";

const FooterStyles = styled.footer`
  padding: 2rem 4rem;
  background-color: var(--black);
  color: var(--white);
  text-align: center;

  @media (max-width: ${MEDIA_QUERY_SMALL_MAX}) {
    font-size: 1.6rem;
  }

  @media (min-width: ${MEDIA_QUERY_MEDIUM}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: unset;
    border-bottom-left-radius: var(--borderRadius);
    border-bottom-right-radius: var(--borderRadius);
  }


  .terms {

    margin-top: 1rem;
    @media (min-width: ${MEDIA_QUERY_MEDIUM}) {
      text-align: right;
      margin-top: 0;
    }
    a {
      color: inherit;
      text-underline-offset: var(--underlineOffset);
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }
`;

const Footer = () => (
  <FooterStyles>
    <div>
      <div>&copy; { new Date().getFullYear()} Tricia Horne Counselling</div>
    </div>
    <div className="terms">
      <Link to="/terms">Terms &amp; Privacy</Link>
    </div>
  </FooterStyles>
);

export default Footer;
