import { graphql, Link, useStaticQuery } from "gatsby";
import React, { useState } from "react";
import { FiPhone } from "react-icons/fi";
import Hamburger from "./Hamburger";
import Img from "gatsby-image";
import { MEDIA_QUERY_MEDIUM } from "../styles/variables";
import Menu from "./Menu";
import styled from "styled-components";

const HeaderStyles = styled.header`
  padding: 1rem 2rem 1rem 1rem;
  display: grid;
  grid-template-columns: 1fr 50px;
  position: relative;
  z-index: 2;

  @media (min-width: ${MEDIA_QUERY_MEDIUM}) {

    padding: 1rem 4rem 0 3rem;
    grid-template-columns: 1fr 300px;

  }

  .contact {
    display: none;

    @media (min-width: ${MEDIA_QUERY_MEDIUM}) {

      display: grid;
      align-items: center;
      justify-content: end;
      font-size: 2.5rem;
      a {
        text-decoration: none;
        display: flex;
        align-items: center;
        transition: 0.2s;
        &:hover {
          color: var(--themeColour);
        }
      }
      svg {
        height: 20px;
        width: 20px;
        margin-right: 5px;
      }
    }
  }

  .burger-menu {
    
    display: grid;
    align-items: center;
    justify-content: end;

    @media (min-width: ${MEDIA_QUERY_MEDIUM}) {
      display: none;
    }
  }

  nav {

    @media (min-width: ${MEDIA_QUERY_MEDIUM}) {
      grid-column: span 2;
    }
  }
`;


const LogoStyles = styled.div`
  a {
    display: grid;
    grid-template-columns: 60px 1fr;
    grid-gap: 1rem;
    text-decoration: none;
    align-items: center;
    line-height: 1.4;
    font-size: 1.6rem;
    font-weight: 500;

    @media (min-width: ${MEDIA_QUERY_MEDIUM}) {
      grid-template-columns: 100px 1fr;
      font-size: 2.4rem;
      grid-gap: 2rem;
    }


    h2 {
      font-size: 1.4rem;
      @media (min-width: ${MEDIA_QUERY_MEDIUM}) {
        font-size: 1.8rem;
      }
    }
  }
`;

const Header = () => {
  
  const [ burgerMenuOpen, setBurgerMenuOpen ] = useState(false);
  const { settings } = useStaticQuery(graphql`
    query {
      settings: sanitySettings {
        headline
        name
        phone
        logo {
          asset {
            fixed(width: 100) {
              ...GatsbySanityImageFixed
            }
            fluid(maxWidth: 100) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  `);

  const telephoneNumberLink = `tel:${settings.phone.replace(/\s/g, "")}`;

  return (
    <HeaderStyles>
      <LogoStyles>
        <Link to="/" title="Home">
          <Img fluid={settings.logo.asset.fluid} />
          <div>
            { settings.name }
            <h2>{ settings.headline }</h2>
          </div>
        </Link>
      </LogoStyles>
      <div className="contact">
        <span className="border"><a href={telephoneNumberLink} title="Call now"><FiPhone />{ settings.phone }</a></span>
      </div>
      <div className="burger-menu">
        <Hamburger isOpen={burgerMenuOpen} setIsOpen={setBurgerMenuOpen} />
      </div>
      <Menu isOpen={burgerMenuOpen} setIsOpen={setBurgerMenuOpen} />
    </HeaderStyles>
  );

};

export default Header;
