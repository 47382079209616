import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  :root {
    --red: #FF4949;
    --black: #2E2E2E;
    --white: #fff;
    --grey: #efefef;
    --themeColour: #aba104;
    --borderRadius: 4px;
    --underlineOffset: 4px;
  }
  html {
    font-size: 10px;
    background: var(--grey);
  }

  .gatsby-image-wrapper img[src*=base64\\,] {
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
  }

  img {
    max-width: 100%;
  }

  span.border {
    display: inline-block;
    padding: 6px;
    border-top: 2px solid var(--themeColour);
    border-bottom: 2px solid var(--themeColour);
  }

`;

export default GlobalStyles;
