import { graphql, Link, useStaticQuery } from "gatsby";
import { MEDIA_QUERY_MEDIUM, MEDIA_QUERY_SMALL_MAX } from "../styles/variables";
import React, { useState } from "react";
import { CgClose } from "react-icons/cg";
import getPageSlug from "../utils/getPageSlug";
import orderPages from "../utils/orderPages";
import styled from "styled-components";

const MenuContainerStyles = styled.nav`

  svg {
    display: none;
    @media (max-width: ${MEDIA_QUERY_SMALL_MAX}) {
      display: block;
      position: absolute;
      top: 2rem;
      right: 2rem;
      width: 4rem;
      height: 4rem;
    }
  }

  @media (max-width: ${MEDIA_QUERY_SMALL_MAX}) {

    --translateX: 0;
    --transition: transform 0.2s ease-in;

    position: fixed;
    top: 0;
    left: 100vw;
    height: 100vh;
    width: 80vw;
    max-width: 260px;
    background-color: var(--white);
    z-index: 1;
    transform: translateX(var(--translateX));
    transition: var(--transition);
    padding-top: 6rem;
    
    &.open {
      --translateX: -100%;
      --transition: transform 0.2s ease-out;
    }
  }

`;

const Overlay = styled.div`
  @media (max-width: ${MEDIA_QUERY_SMALL_MAX}) {
    position: fixed; 
    top: 0; 
    left: 0; 
    width: 100vw; 
    height: 200vh; 
    background-color: rgba(0,0,0,0.6);
  }
`;


const MenuListStyles = styled.ul`

  margin: 0;
  padding: 0 1rem 1rem;
  list-style-type: none;
  
  li {
    a {
      display: block;
      padding: 1rem 2rem;
      color: var(--themeColour);
      font-size: 2rem;
      background-color: var(--white);
      text-underline-offset: var(--underlineOffset);

      @media (max-width: ${MEDIA_QUERY_SMALL_MAX}) {
        &[aria-current] {
          color: var(--black);
          text-decoration: none;
        }
      }

    }
  }

  @media (min-width: ${MEDIA_QUERY_MEDIUM}) {

    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    /* position: relative;
    z-index: 1; */
    li a {
      &:hover {
        background-color: var(--themeColour);
        color: var(--white);
        transition: 0.3s;
        text-decoration: none;
      }
    }

  }

`;


const MenuListItemStyles = styled.li`
  @media (min-width: ${MEDIA_QUERY_MEDIUM}) {
    display: inline-flex;
    position: relative;
  }
`;

const SubMenuStyles = styled.ul`

  margin: 0;
  padding: 0;
  list-style-type: none;

  @media (max-width: ${MEDIA_QUERY_SMALL_MAX}) {
    li a {
      display: block;
      padding-left: 4rem;
      font-size: 1.8rem;
    }
  }

  @media (min-width: ${MEDIA_QUERY_MEDIUM}) {
    position: absolute;
    left: 50%;
    top: 100%;
    transform: translateX(-50%);
    background-color: var(--white);
    box-shadow: 0 15px 30px -10px rgba(0, 0, 0, 0.1);
    border-bottom-left-radius: var(--borderRadius);
    border-bottom-right-radius: var(--borderRadius);
    display: none;
    li a {
      font-size: 1.6rem;
      text-align: center;
      white-space: nowrap;
      text-underline-offset: var(--underlineOffset);
    }

    li:last-child {
      a {
        border-bottom-left-radius: var(--borderRadius);
        border-bottom-right-radius: var(--borderRadius);
      }
    }
  }

`;

const SubMenuOpenStyles = styled(SubMenuStyles)`
  display: block;
`;


const MenuItem = ({ allPages, menuItem, setIsOpen }) => {

  const [ subMenuActive, setSubMenuActive ] = useState(false);

  // check for sub pages
  const subPages = allPages.filter(page => page?.parentPage?._id == menuItem._id);

  const handleClick = () => {

    setIsOpen(false);
    setSubMenuActive(false);
  
  };

  return (
    <>
      {/* { subMenuActive && <Overlay />} */}
      <MenuListItemStyles
        onMouseEnter={() => setSubMenuActive(true)}
        onMouseLeave={() => setSubMenuActive(false)}
        onClick={handleClick}
      >
        <Link to={getPageSlug(menuItem)}>{menuItem.name}</Link>
        <SubMenu 
          menuItems={subPages} 
          active={subMenuActive}
          onMouseEnter={() => setSubMenuActive(true)}
          onMouseLeave={() => setSubMenuActive(false)}
        />
      </MenuListItemStyles>
    </>
  );

};

const SubMenu = ({ menuItems, active }) => {
  
  if (!menuItems.length) {

    return (<></>);
  
  }

  // arrange menu items as
  const orderedMenuItems = orderPages(menuItems);

  const SubMenuType = active ? SubMenuOpenStyles : SubMenuStyles;

  return (
    <SubMenuType>
      { orderedMenuItems.map(item => {
        
        const slug = item.parentPage ? 
          `${item.parentPage.slug.current}/${item.slug.current}` : 
          item.slug.current;

        return (
          <li key={slug}><Link to={`/${slug}`}>{ item.name }</Link></li>
        );

      })}
    </SubMenuType>
  );

};

const Menu = ({ isOpen, setIsOpen }) => {

  const { allMenuItems, allPages } = useStaticQuery(graphql`
    query {
      allMenuItems: allSanityMenu(filter: {_id: {eq: "SiteMenu"}}) {
        nodes {
          menuItem {
            _id
            name
            slug {
              current
            }
            parentPage {
              slug {
                current
              }
            }
          }
        }
      }
      allPages: allSanityPage {
        nodes {
          name
          order
          slug {
            current
          }
          parentPage {
            _id
            slug {
              current
            }
          }
        }
      }
    }
  `);

  const menuItems = allMenuItems?.nodes?.[0]?.menuItem;

  if (!menuItems) {

    return (<></>);
  
  }

  
  return (
    <>
      { isOpen && <Overlay /> }
      <MenuContainerStyles className={isOpen ? "open" : ""}>
        <MenuListStyles>
          { menuItems.map((menuItem, index) => (
            <MenuItem key={`menu-item-${index}`} allPages={allPages.nodes} menuItem={menuItem} setIsOpen={setIsOpen} />
          ))}
        </MenuListStyles>
        {isOpen && <CgClose onClick={() => setIsOpen(false)} />}
      </MenuContainerStyles>
    </>
  );

};

export default Menu;
