import { graphql, Link, useStaticQuery } from "gatsby";
import React from "react";
import styled from "styled-components";

const ContactStyles = styled.section`
  padding: 4rem;
  background-color: var(--themeColour);
  color: var(--white);
  text-align: center;
  font-weight: 500;
  line-height: 1.5;
  font-size: 2rem;
  a {
    color: var(--white);
    white-space: nowrap;
    text-underline-offset: var(--underlineOffset);
  }
`;

const Contact = () => {
  
  const { settings } = useStaticQuery(graphql`
    query {
      settings: sanitySettings {
        email
        phone
      }
    }
  `);

  const telephoneNumberLink = `tel:${settings.phone.replace(/\s/g, "")}`;
  const emailLink = `mailto:${settings.email}`;

  return (
    <ContactStyles id="contact">
      Get in touch by email at <a href={emailLink}>
        { settings.email }
      </a> or by phone on <a href={telephoneNumberLink}>
        { settings.phone }
      </a>
    </ContactStyles>
  );

};

export default Contact;
